import React, { useMemo, useState } from 'react';
import { Button, Menu, Dropdown /* , Badge */ } from 'antd';
import UserMenuDropdown from '@components/UserMenuDropdown';
// import NotificationMenuDropdown from '@components/NotificationMenuDropdown';
import SearchBar from '@components/SearchBar';
import CampaignBreadcrumbs from '@components/CampaignBreadcrumbs';
import { ReactComponent as HelpSvg } from '@assets/icons/help.svg';
// import { ReactComponent as NotificationSvg } from '@assets/icons/notification.svg';
import { ReactComponent as UploadSvg } from '@assets/icons/upload.svg';
import { useDispatch } from 'react-redux';
import { toggleUploadAssetModal } from '@redux/actions/modalAction';
import { NotificationFeed } from '@components/NotificationFeed';
import { useCurrentWorkspace } from '@hooks/workspace';
import { useNavigate } from 'react-router-dom';

function AppHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [currentWorkspace] = useCurrentWorkspace(false);
  const workspaceId = currentWorkspace?.id;

  const menu = useMemo(() => {
    return (
      <Menu>
        <Menu.Item
          key="0"
          onClick={() => {
            window.open('https://help.streamwork.com/knowledge', '_blank');
          }}
        >
          Help center
        </Menu.Item>
        <Menu.Item
          key="1"
          onClick={() => {
            window.open('https://www.streamwork.com/contact', '_blank');
          }}
        >
          Contact support
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => {
            window.open('https://www.streamwork.com/contact', '_blank');
          }}
        >
          Submit product feedback
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => {
            navigate(`/start/${workspaceId}`);
          }}
        >
          Onboarding
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="4"
          onClick={() => {
            window.open('https://www.streamwork.com/blog', '_blank');
          }}
        >
          What&apos;s new?
        </Menu.Item>
      </Menu>
    );
  }, [workspaceId, navigate]);
  return (
    <div className="heade_container">
      <CampaignBreadcrumbs showSearch={showSearch} />
      <SearchBar showSearch={showSearch} setShowSearch={setShowSearch} />
      <Button
        type="primary"
        className="heade_container__btn"
        onClick={() =>
          dispatch(
            toggleUploadAssetModal({
              visible: true,
              postAction: 'redirect-to-campaign',
              workspaceId: currentWorkspace?.id || ''
            })
          )
        }
      >
        <UploadSvg />
        Upload
      </Button>
      {/* <Badge count={5} size="small" offset={[-12, 12]}>
        <NotificationMenuDropdown>
          <Button
            type="text"
            onClick={(e) => {
              if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
              }
            }}
            className="heade_container__notification"
          >
            <NotificationSvg />
          </Button>
        </NotificationMenuDropdown>
      </Badge> */}
      <NotificationFeed />
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        overlayClassName="help_dropdown"
        trigger={['click']}
      >
        <Button
          className="heade_container__help"
          type="text"
          onClick={(e) => {
            if (document.activeElement instanceof HTMLElement) {
              document.activeElement.blur();
            }
          }}
        >
          <HelpSvg />
        </Button>
      </Dropdown>
      <UserMenuDropdown />
    </div>
  );
}

export default AppHeader;
