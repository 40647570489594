import React, { useEffect, useMemo, useState } from 'react';
import { Menu, Dropdown, Divider, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { ReactComponent as DashboardSvg } from '@assets/icons/dashboard.svg';
import { ReactComponent as ScheduleSvg } from '@assets/icons/schedule.svg';
import { ReactComponent as CompaingsSvg } from '@assets/icons/compaings.svg';
import { ReactComponent as AssetsSvg } from '@assets/icons/assets.svg';
import { ReactComponent as TasksSvg } from '@assets/icons/tasks.svg';
import { ReactComponent as ArrowDownSvg } from '@assets/icons/arrow-down.svg';
import { ReactComponent as UpgradePlanSvg } from '@assets/icons/diamond-upgrade.svg';
import { useCurrentWorkspace } from '@hooks/workspace';
import { useOrganization } from '@components/OrganizationBoundary';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import ImageComponent from '@components/ImageComponent';
import { WorkspaceMenu } from '@components/EntitiesDropdownMenu/WorkspaceDropDownMenu';
import { IsOnboardingCompleted } from '@helpers/StartOnboardingSteps';
import { useAuth } from '@hooks/useAuth';
import moment from 'moment';
import UpgradePlanTooltip from '@components/Tooltip/UpgradePlanTooltip';

const { SubMenu } = Menu;

const rootSubmenuKeys = ['campaigns', 'assets', 'tasks'];

function MenuPanel() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user } = useAuth(true, 'internal');
  const [currentWorkspace] = useCurrentWorkspace(false);
  const { currentOrganization } = useOrganization();
  const workspaceId = currentWorkspace?.id ?? '';

  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const upgradeUniversalTasksView =
    !currentOrganization.entity?.features.universalTaskView;

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey || '') === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const isOnboardingVisible = useMemo(() => {
    const userCreatedDaysAgo = moment().diff(user.createdAt, 'days');
    const orgCreatedDaysAgo = currentOrganization.entity?.createdAt
      ? moment().diff(currentOrganization.entity.createdAt, 'days')
      : Number.POSITIVE_INFINITY;
    const onboardingVisibleDays = Math.min(
      userCreatedDaysAgo,
      orgCreatedDaysAgo
    );
    if (onboardingVisibleDays > 14) return false;
    return !IsOnboardingCompleted(
      currentWorkspace?.role === 'workspace://contributor',
      user.completedOnboardingSteps
    );
  }, [
    user.createdAt,
    user.completedOnboardingSteps,
    currentOrganization.entity?.createdAt,
    currentWorkspace?.role
  ]);

  useEffect(() => {
    let path = pathname;
    const keys = [path];
    let i = path.lastIndexOf('/');
    while (i > 0) {
      path = path.substring(0, i);
      i = path.lastIndexOf('/');
      keys.push(path);
    }
    setOpenKeys(keys);
    setSelectedKeys(keys);
  }, [pathname]);

  return (
    <>
      <div className="manage_workspace">
        <div className="manage_workspace__indicator" />
        <div className="manage_workspace__name" title={currentWorkspace?.name}>
          {currentWorkspace?.name}
        </div>
        <Dropdown
          className="manage_workspace__container"
          overlayClassName="manage_workspace__overlay"
          trigger={['click']}
          overlay={
            <div className="workspace_container">
              <div className="workspace_info">
                <div className="workspace_info__image">
                  {currentWorkspace && (
                    <ImageComponent
                      alt={`${currentWorkspace.name} avatar`}
                      src={currentWorkspace.picture.url}
                      width={40}
                      height={40}
                      types={['png', 'webp']}
                      id={currentWorkspace.id}
                      name={currentWorkspace.name}
                    />
                  )}
                </div>
                <div className="workspace_info__copys">
                  <Tooltip
                    placement="top"
                    overlayClassName="toggle_favorite_overlay"
                    title={currentWorkspace?.name}
                  >
                    <div className="name main-body-text main-body-text--semibold">
                      {currentWorkspace?.name}
                    </div>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    overlayClassName="toggle_favorite_overlay"
                    title={`${currentOrganization.entity?.subdomain}.streamwork.com`}
                  >
                    <span className="subname main-body-text main-body-text--semibold main-body-text--tiny main-body-text--dark-gray">{`${currentOrganization.entity?.subdomain}.streamwork.com`}</span>
                  </Tooltip>
                </div>
              </div>
              <Divider style={{ margin: '16px 0px 8px' }} />
              {currentWorkspace && (
                <WorkspaceMenu workspace={currentWorkspace} />
              )}
            </div>
          }
          placement="bottom"
        >
          <div className="manage_workspace__action">
            <ArrowDownSvg />
          </div>
        </Dropdown>
      </div>
      <div className="menu_container">
        <OverlayScrollbarsComponent className="menu_panel__wr">
          <Menu
            className="menu_panel"
            theme="dark"
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            onOpenChange={onOpenChange}
            mode="inline"
          >
            <SubMenu
              key="/campaigns"
              title="Campaigns"
              className="campaigns-menu-item"
              icon={<CompaingsSvg className="campaigns_icon" />}
              onTitleClick={({ key }) => {
                setSelectedKeys([key]);
                setOpenKeys(['/campaigns/all']);
                navigate('/campaigns/all');
              }}
            >
              <Menu.Item key="/campaigns/all">
                <Link to={`/campaigns/all/${workspaceId}`}>All Campaigns</Link>
              </Menu.Item>
              {currentWorkspace?.permissions.createCampaigns && (
                <Menu.Item key="/campaigns/my">
                  <Link to={`/campaigns/my/${workspaceId}`}>My Campaigns</Link>
                </Menu.Item>
              )}
              <Menu.Item key="/campaigns/favorite">
                <Link to={`/campaigns/favorite/${workspaceId}`}>Favorites</Link>
              </Menu.Item>
              <Menu.Item key="/campaigns/deleted">
                <Link to={`/campaigns/deleted/${workspaceId}`}>Deleted</Link>
              </Menu.Item>
            </SubMenu>
            {isOnboardingVisible && (
              <Menu.Item
                key="/start"
                icon={<ScheduleSvg />}
                onClick={({ key }) => {
                  setSelectedKeys([key]);
                  setOpenKeys([key]);
                }}
              >
                <Link to={`/start/${workspaceId}`}>Onboarding</Link>
              </Menu.Item>
            )}

            <Menu.Item
              key="/dashboard"
              icon={<DashboardSvg />}
              onClick={({ key }) => {
                setSelectedKeys([key]);
                setOpenKeys([key]);
              }}
            >
              <Link to={`/dashboard/${workspaceId}`}>Dashboard</Link>
            </Menu.Item>

            <SubMenu
              key="/assets"
              className="sub_menu"
              title="Media"
              icon={<AssetsSvg className="assets_icon" />}
              onTitleClick={({ key }) => {
                setSelectedKeys([key]);
                setOpenKeys(['/assets/all']);
                navigate('/assets/all');
              }}
            >
              <Menu.Item key="/assets/all">
                <Link to={`/assets/all/${workspaceId}`}>All Media</Link>
              </Menu.Item>
              <Menu.Item key="/assets/my">
                <Link to={`/assets/my/${workspaceId}`}>My Media</Link>
              </Menu.Item>
              <Menu.Item key="/assets/favorite">
                <Link to={`/assets/favorite/${workspaceId}`}>Favorites</Link>
              </Menu.Item>
              <Menu.Item key="/assets/deleted">
                <Link to={`/assets/deleted/${workspaceId}`}>Deleted</Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="/tasks"
              className="sub_menu"
              title={
                <UpgradePlanTooltip
                  text=" to a Team plan to access this feature."
                  isCanVisible={upgradeUniversalTasksView}
                >
                  Tasks
                </UpgradePlanTooltip>
              }
              disabled={upgradeUniversalTasksView}
              icon={
                upgradeUniversalTasksView ? (
                  <Icon component={UpgradePlanSvg} />
                ) : (
                  <TasksSvg className="tasks_icon" />
                )
              }
              onTitleClick={({ key }) => {
                setSelectedKeys([key]);
                setOpenKeys(['/tasks/all']);
                navigate('/tasks/all');
              }}
            >
              <Menu.Item key="/tasks/all">
                <Link to={`/tasks/all/${workspaceId}`}>All Tasks</Link>
              </Menu.Item>
              <Menu.Item key="/tasks/my">
                <Link to={`/tasks/my/${workspaceId}`}>Tasks I created</Link>
              </Menu.Item>
              <Menu.Item key="/tasks/assigned">
                <Link to={`/tasks/assigned/${workspaceId}`}>
                  Assigned to me
                </Link>
              </Menu.Item>
              <Menu.Item key="/tasks/deleted">
                <Link to={`/tasks/deleted/${workspaceId}`}>Deleted</Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </OverlayScrollbarsComponent>
      </div>
    </>
  );
}

export default MenuPanel;
